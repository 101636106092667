.footer {
  bottom: 0;
  width: 100vw;
  min-height: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.socialMedia svg,
.socialMedia a {
  color: rgb(255, 0, 0);
  margin: 20px;
  font-size: 50px;
  cursor: pointer;
  text-decoration: none;
}

.footer p {
  color: rgb(0, 255, 13);
}

.importantInfo a {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.importantInfo a:hover {
  color: red;
  text-decoration: underline;
}