.navbar {
  width: 100svw;
  height: 80px;
  background-color: #000000;
  display: flex;
  flex-direction: row;
}

.navbar .leftSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 150px;
}

.navbar .leftSide img {
  width: 70px;
}

.navbar .rightSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  margin: 10px;
  font-size: 18px;
}

.navbar a:hover {
  font-size: 20px;
  color: rgb(255, 0, 0);
  transition: 0.3s ease-in;
}

.navbar a.active {
  font-size: 22px;
  border-radius: 15%;
  background-color: rgba(255, 0, 0, 0.552);
  padding: 10px;
  color: rgb(255, 255, 255);
}

.navbar .rightSide button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.navbar .rightSide svg {
  font-size: 40px;
}

.navbar #open {
  padding-left: 0px;
}

.navbar #open img {
  display: none;
}

.navbar #close img {
  display: inherit;
}

.navbar #open .hiddenLinks {
  display: inherit;
  margin-left: 30px;
}

.navbar #close .hiddenLinks {
  display: none;
}

.navbar #open a {
  width: 70px;
  margin: 5px;
}

@media only screen and (max-width: 900px) {
  .navbar .rightSide a {
    width: 65px;
  }

  .navbar .leftSide {
    padding-left: 50px;
  }

  .navbar #open img {
    display: none;
  }

  .navbar #close img {
    display: inherit;
  }
}

@media only screen and (max-width: 600px) {
  .navbar .rightSide a {
    display: none;
  }

  .navbar .rightSide {
    justify-content: flex-end;
    padding-right: 50px;
  }

  .navbar .rightSide button {
    display: inherit;
  }

  .navbar #open img {
    display: none;
  }

  .navbar #close img {
    display: inherit;
  }
}

@media only screen and (min-width: 601px) {
  .navbar .rightSide button {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  .navbar .leftSide a {
    display: none;
  }

  .navbar #open img {
    display: none;
  }

  .navbar #close img {
    display: inherit;
  }

}