.social {
  background-color: gray;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social .socialTitle {
  font-family: sans-serif, Arial;
  font-size: 60px;
}

.socialList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

/* social ITEM STYLING */

.socialItem {
  background-color: lightgray;
  border-radius: 15px;
  width: 300px;
  height: 350px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.socialItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.socialItem div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.socialItem a {
  text-decoration: none;
  color: black;
  margin: 20px;
  font-size: 20px;
}

.socialItem h1,
.socialItem p {
  margin-left: 20px;
}

@media only screen and (max-width: 1300px) {
  .social {
    height: 100%;
  }

  .socialList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .socialList {
    grid-template-columns: 1fr;
  }
}