.about {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .aboutTop {
    width: 100vw;
    height: 45%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: sans-serif, Arial;
  }
  
  .aboutBottom {
    font-family: 'Comic Sans', monospace, sans-serif, Arial;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 10px;
  }
  
  .aboutBottom p {
    width: 70svw;
    font-size: 23px;
    display: flex;
    justify-content: center;
  }
  
  .about .aboutBottom h1 {
    font-weight: 400;
    font-size: 70px;
    color: black;
    height: 30px;
    display: flex;
    justify-content: center;
    transform: translateY(-40px);
  }

  .discordInvAbout, .discordInvAbout a {
    font-family: 'Comic Sans', monospace, sans-serif, Arial;
    background-color: #121619;
    display: flex;
    justify-content: center;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    font-size: 25px;
    border-radius: 15px;  
  }
  
  .discordInvAbout a:hover {
    text-decoration: none;
    font-size: 28px;
    cursor: pointer;
    border-radius: 30px;
    transition: 0.3s ease-in;
  }

  .discordIntAbout iframe {
    /*display: flex;
    justify-content: center;
    width: 400px;
    height: 300px;*/
    display: none;
  }