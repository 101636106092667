.discord-container {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

iframe {
    height: 350px;
    width: 400px;
    overflow: hidden;
    border-radius: 10%;
    border: none;
}

.discord-iframe {
    height: 350px;
    width: 400px;
    background-color: black;
    border: 1px solid black;
    padding: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10%;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.discord-container.minimized .discord-iframe {
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
}

.discord-toggle {
    background-color: #262731;
    border: none;
    color: #7289DA;
    /* Discord Blurple */
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.discord-container.minimized .discord-toggle {
    transform: rotate(360deg);
    /* Rotate for minimized state */
}

@media only screen and (max-width: 1030px) {
    .discord-container {
        display: none;
    }
}