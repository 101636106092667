.home {
  width: 100svw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.headerContainer {
  border-radius: 10px;
  height: auto;
  width: 50svw;
  margin-left: 30px;
  padding: 16px;
  background-color: rgba(32, 31, 31, 0.40);
}

.headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-size: 90px;
  height: auto;
  font-weight: 50;
  color: black;
  width: auto;
}

.headerContainer p {
  padding: 0;
  font-size: 40px;
  font-weight: lighter;
  color: black;
  width: auto;
}

.headerContainer button {
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 18px;
  cursor: pointer;
  justify-content: center;
  background-color: black;
  height: 50px;
  width: 180px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 20px;
}

.headerContainer button:hover {
  background-color: #ff0000;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s ease-in;
}

@media only screen and (max-width: 765px) {
  .home {
    justify-content: center;
    align-items: center;
  }

  .headerContainer h1,
  .headerContainer p {
    display: flex;
  }

  .headerContainer {
    margin-left: 0px;
    border-radius: 10px;
    padding: 10px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #121619;
    color: white;
  }

  .headerContainer h1 {
    font-size: 40px;
    height: 30px;
    color: white;
  }

  .headerContainer p {
    font-size: 30px;
    color: white;
  }

  .headerContainer button {
    background-color: rgb(255, 255, 255);
    color: #000000;
    margin-bottom: 30px;
  }

  .headerContainer button:hover {
    background-color: #ff0000;
    color: rgb(255, 255, 255);
  }
}

@media only screen and (max-width: 500px) {

  .headerContainer,
  .headerContainer h1,
  .headerContainer p {
    justify-content: center;
    display: flex;

  }
}

@media only screen and (max-width: 1030px) {}

@media only screen and (max-width: 920px),
(max-height: 630px) {
  .home {
    justify-content: center;
    align-items: center;
  }

  .headerContainer h1,
  .headerContainer p {
    display: flex;
  }

  .headerContainer {
    margin-left: 0px;
    border-radius: 10px;
    padding: 10px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #121619;
    color: white;
  }

  .headerContainer h1 {
    font-size: 40px;
    height: 30px;
    color: white;
  }

  .headerContainer p {
    font-size: 30px;
    color: white;
  }

  .headerContainer button {
    background-color: rgb(255, 255, 255);
    color: #000000;
    margin-bottom: 30px;
  }

  .headerContainer button:hover {
    background-color: red;
    color: rgb(255, 255, 255);
  }

}